import type { PageMetadata } from '~/lib/open-graph';

export const SITE_META: PageMetadata = {
  title: 'Tips to Building Products - unknownkind',
  description: `Hi 👋 — I’m Eddy.
  I write about building Product Experiences.
  `,
  type: 'website',
  url: 'https://www.unknownkind.com',
  image: 'https://assets.unknownkind.com/portrait_800x800.jpg',
  siteName: 'unknownkind',
};
