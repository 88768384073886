type PageType =
  | 'website'
  | 'article'
  | 'book'
  | 'profile'
  | 'music.song'
  | 'music.album'
  | 'music.playlist'
  | 'music.radio_station'
  | 'video.movie'
  | 'video.episode'
  | 'video.tv_show'
  | 'video.other';

export type PageMetadata = {
  title: string;
  description: string;
  type: PageType;
  url?: string;
  image?: string;
  siteName?: string;
};

type OGDescriptor = {
  property: string;
  content: string;
};

export function generateOgTags(page: PageMetadata): OGDescriptor[] {
  const entries = Object.entries(page);

  // return [
  //   { property: 'og:title', content: page.title },
  //   { property: 'og:type', content: page.type },
  //   { property: 'og:url', content: page.url },
  //   { property: 'og:image', content: page.image },
  //   { property: 'og:description', content: page.description },
  //   { property: 'og:site_name', content: page.siteName },
  // ];
  return entries.map(([key, value]) => ({
    property: `og:${key}`,
    content: value,
  }));
}
